<template lang="pug">
.wrapper.i-wrap
  .section-left
  .section-mid
    .container(v-if="loading")
      Spinner
    transition(name="slideup")
      .user-container(v-if="!loading")
        //- h3 Hello {{user.displayName}}!
        .user-top
          .user-pic-wrap
            img.user-photo( v-if="user.photo && uploadedPhoto == false" v-bind:src="user.photo")
            img.user-photo( v-else-if="user.photo && uploadedPhoto == true " v-bind:src="dataCroppedImage")
            img.user-photo.default(v-else src="@/assets/img/awakescore1.png")
            label.pointer(@click="uploadImageActive") 上傳照片
          .user-info-right 
            .user-info-grp
              label 顯示名稱
              input(v-model="updateInfo.displayName" )
            .user-info-grp
              label 名字
              input(v-model="updateInfo.firstName" )
            .user-info-grp
              label 姓
              input(v-model="updateInfo.lastName" )
            .user-info-grp
              label 性別
              select(v-model="updateInfo.gender")
                option(value="male") Male
                option(value="female") Female
                option(value="other") other
                option(value="unknown") Pref not to say
            .user-info-grp
              label 生日
              Datepicker(
                v-model="updateInfo.dob"              
              )
          div(v-if="user.adminLevel == 'critic'")
            label Publication:
            input(v-model="updateInfo.publication" )
        .user-save
          button(@click="userUpdateInfo") Save
      
    transition(name="fade")
      .overlay(@click="closeModal" v-show="overlay")
    transition(name="slideup")
      .modal.uploadImageModal(v-on:keyup.esc="closeModal" v-show="uploadImageModal")
        .uploadPic()
          input( type="file" accept="image/*" @change="onChange")
          VueCropper(v-if="imageUrl"
            style="width:80%;margin:0 auto;" 
            ref="cropper"
            :src="imageUrl"
            alt="Source Image"
            :aspectRatio="1/1"
            preview=".preview"
            :viewMode="1"
            )
          //- #preview(v-bind:class="{active : imageUrl}")
            //- img(v-if="user.photo && !imageUrl" v-bind:src="user.photo")
        .user-save
          button(@click="uploadImage") Submit      
</template>

<script>
import Spinner from 'vue-simple-spinner'
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

import Datepicker from 'vuejs-datepicker';
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-vue';
import { mapActions, mapState } from "vuex";

export default {  
  name: 'User',
  components:{
    Spinner,
    VueCropper,
    Datepicker,
  },
  data(){
    return{      
      loading:true,
      updateInfo:{},
      date: new Date(1990,1,1),
      file:null,
      imageUrl:null,
      uploadImageModal:false,
      overlay:false,
      uploadedPhoto:false,
      croppedImage:null,
      dataCroppedImage:'',
    }
  },
  computed:{
    ...mapState(['user']),
  },
  metaInfo(){
    var x = this.loading;
    var theName = '';
    if (!x){
      if (this.user.displayName){
        theName = this.user.displayName
      }else{
        theName = this.user.displayName
      }
    }
    return {
      title: this.loading ? 'Loading...': theName,
      titleTemplate: "%s - 臭豆腐電影"
    }
  },
  methods:{
    async init(){
      this.updateInfo = {...this.user};
      this.loading=false;
    },
    async userUpdateInfo(){
      let payload = {}
      payload.displayName = this.updateInfo.displayName;
      payload.firstName = this.updateInfo.firstName;
      payload.lastName = this.updateInfo.lastName;
      payload.gender = this.updateInfo.gender;
      payload.dob = this.updateInfo.dob;
      // payload.displayName = updateInfo.displayName;

      var updateInfo = await this.axios.post('/api/user/updateInfo',payload)
      if(updateInfo.data.message == 'ok') this.flash('Updated', 'success')
      else this.flash('error occurred', 'error')
    },
    uploadImageActive(){
      this.uploadImageModal = true;
      this.overlay = true;
    },
    closeModal(){
      this.uploadImageModal = false;
      this.overlay= false;
      
    },
    onChange(e) {
      this.imageUrl = null;
      const file = e.target.files[0]
      const reader = new FileReader();
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      this.file = file
      // this.imageUrl = URL.createObjectURL(file)

      reader.onload = (event) => {
        this.imageUrl = event.target.result;
        // rebuild cropperjs with the updated source
        // this.$refs.cropper.replace(event.target.result);
      };
      reader.readAsDataURL(file);

    },
    uploadImage(event) {    
      try{
        if(this.file){
          this.dataCroppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
          this.$refs.cropper.getCroppedCanvas({fillColor:'#f5f5f5'}).toBlob(async(blob)=>{
            var formData = new FormData();
            this.croppedImage = new File([blob], "filename.jpg",{type:'image/jpeg'});
            // formData.append('file',this.file)
            formData.append('file',this.croppedImage)
            // console.log(formData.has('file'))
            var uploadImg = await this.axios.post( `/api/user/uploadImg`,formData,{headers: {'Content-Type': 'multipart/form-data'}})
            if(uploadImg.data) {
              this.flash('Image Uploaded','success')
              this.closeModal();
              this.uploadedPhoto = true;
              
            }
          },'image/jpeg');
        
        }
        
      }catch(err){
        this.flash(err,'error')
      }
    }
   
  },
  mounted() {
    this.init();
  }
}
</script>
<style scoped lang="scss">
  .user-container{
    margin-top:20px;
    padding:20px 0;
    width:100%;
    display:flex;
    flex-direction: column;
    &:hover{
      box-shadow: 0 0 1px rgb(39 44 49 / 10%), 0 3.2px 16px rgb(39 44 49 / 7%);
      transform: translate3D(0, -1px, 0);
      transition: transform 0.3s ease-in, box-shadow 0.3s ease-in;
    }
    background-color: #fcfcfc;
    border-radius: 4px;
    padding: 16px;
    perspective: 1000px;
    box-shadow: 8px 6.4px 38.4px rgb(39 44 49 / 6%), 1px 3.2px 8px rgb(39 44 49 / 3%);
    transition: transform 0.5s ease-out, box-shadow 0.5s ease-out;
  }
  .user-top{
    display:flex;
  }
  .user-info-grp{
    display:flex;
    margin:10px 0;
    align-items: center;
  }
  .user-pic-wrap{
    width:30%;
    display:flex;
    flex-direction:column;
    align-items:center;
    label{margin-top:10px}
  }
  .user-photo{
    width:100%;
  }
  .user-info-right{
    display: flex;
    width: 70%;
    padding:20px;
    flex-direction: column;
    align-items: baseline;

  }
  
    label{
      margin:0 10px;
      font-size: 18px;
      font-weight: 700;
      border: solid 1px lightgrey;
      padding: 3px 10px;
      border-radius: 5px;
    }
  .uploadImageModal{
    border:1px solid #e6e6e6;
    min-height:200px;
    overflow:auto;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .uploadPic{
    width:100%;
    height:80%;
    display:flex;
    flex-direction: column;
    align-items: center;
    input{
      width:100%;
    }
  }
  #preview{
    max-width:100%;
    max-height: 545px;
    overflow:hidden;
    img{
      width:100%;
    }
  }
  .user-save{
    width:100%;
    padding:20px;
    display:flex;
    justify-content: flex-end;
  }
  @media all and (max-width:768px){
    .user-top{
      flex-direction:column;
    }
    .user-pic-wrap{
      // width:100%;
      margin:0 auto;
    }
    .user-info-right{
      width:100%;
      margin:0 auto;
      align-items:center;
    }
    .user-info-grp{
      flex-direction: column;
      label{
        margin-bottom:10px;
      }
    }
  }
</style>
